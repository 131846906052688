<template>
<svg enable-background="new 0 0 309 368" :fill="hex" version="1.1" viewBox="0 0 309 368" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<path class="st0" d="m196.4 265.7c3.2 0.3 5.4 0.8 6.8 1.4 1.6 0.8 3.9 2.2 6.7 4.3-0.9 1.9-9.2 18.8-24.7 50.7-5-0.8-7-2.7-5.9-5.7s1.9-5.5 2.5-7.5l1-28.7c0-0.6 0.5-1 1-1l4.4 0.3c2.5-2 4.2-3.9 5-5.5 0.7-1.3 1-3.4 1.1-6.3 0-1.1 0.9-2 2-2h0.1z"/>
<path class="st0" d="m57.6 352.4c1.1 3 1.7 5.2 1.7 6.7 0 1.8-0.3 4.4-0.9 7.9h-56.4c-1.5-4.8-0.6-7.5 2.5-7.8 3.2-0.4 5.8-0.7 7.9-1l26.2-11.7c0.5-0.2 1.1 0 1.3 0.5l1.7 4.1c2.9 1.4 5.3 2 7.1 2 1.5 0 3.5-0.6 6.2-1.8 1-0.5 2.2 0 2.7 1v0.1z"/>
<path class="st1" d="m188.1 279.5c2.5-2 4.2-3.9 5-5.5 0.7-1.3 1-3.4 1.1-6.3 0-0.4 0.2-0.8 0.4-1.2-4.2-1.6-9.6-3.5-15.6-5.6l-5.4 17.1c4.1 0.5 7.9 0.9 11.1 1.2l3.4 0.3z"/>
<path class="st2" d="m218.9 174.2c15.6 28.1 28.7 48.4 39.3 60.8l16-3.1c-10.3-64.1-40.2-111.4-89.7-141.9h-8.8c-19.8 67.5-25.3 112.5-16.4 135h68.9c-1-16.4-4.5-33.8-9.3-50.8z"/>
<g class="st3">
<path d="m132.4 196.4c5.6-28.2 26.8-58.6 28.5-98.1-16 20.1-23.4 58.1-35.1 63.5-11.9 5.5-28.7 0.2-48.7-7l-6.1 13.7c14.6 15.8 42.6 29.7 61.1 29.5 0.1-0.6 0.2-1.1 0.3-1.6z"/>
</g>
<path class="st4" d="m175.4 91.2l-14.4 2.3c0 41.9-22.8 73.5-28.6 102.9-0.5 2.5-1.2 7.6 2.3 7.6h20.7c-1.3-25.1 5.4-62.7 20-112.8z"/>
<path class="st5" d="m175.4 91.2l-14.4 2.3c0 41.9-22.8 73.5-28.6 102.9-0.5 2.5-1.2 7.6 2.3 7.6h20.7c-1.3-25.1 5.4-62.7 20-112.8z"/>
<path class="st3" d="m73.2 265.2c-9-6.4-14-31.1 1.9-37.9 15.4-6.6 39.8-16.9 57.8-24.2-0.3-0.4-0.5-0.9-0.7-1.5-44.7 11.9-67.6 19.7-68.8 23.5v0.1l-0.1 0.1c-12.2 16.9-19.5 98.7-21.9 104.9l23.4 3.5c5.9-22.1 20.6-39.7 29.5-60.7-6.7-1.8-21.1-7.4-21.1-7.8z"/>
<path class="st6" d="m155.4 204h-20.7c-0.9 0-1.5-0.3-1.9-0.8-18 7.3-42.4 17.6-57.8 24.2-15.9 6.8-10.9 31.5-1.9 37.9 0 0.4 14.5 6 21.1 7.8 38.6 10.3 68.1 3.1 79 5.9l5.9-18.7-19.2-6.6c5.8-1.2 11.2-2.2 15.8-3 19.1-3.4 29.5-11.2 33.1-25.6h-49.4c-2.2-5.7-3.5-12.7-4-21.1z"/>
<path class="st7" d="m261.5 256.8c0.1-0.4 0.2-0.9 0.3-1.4-9.9-14.5-25-25.4-42.8-30.4h-10.2c-3.6 14.4-13.9 22.3-33.1 25.6-4.6 0.8-9.9 1.8-15.8 3l19.2 6.6-0.2 0.6c6 2.1 11.4 4 15.6 5.6 0.4-0.5 1-0.8 1.6-0.8h0.1c3.2 0.3 5.4 0.8 6.8 1.4 1.6 0.8 3.9 2.2 6.7 4.3-0.9 1.9-9.2 18.8-24.7 50.7-5-0.8-7-2.7-5.9-5.7s1.9-5.5 2.5-7.5l1-28.7c0-0.6 0.5-1 1-1l4.4 0.3-3.5-0.2c-3.2-0.4-6.9-0.8-11.1-1.2v0.1l-0.3 0.8c-8.1-2.1-26.3 1.3-50.8-0.9-1.4 5.6-2.2 11.5-2.2 17.5 0 40.6 34.5 73.5 77 73.5s77-32.9 77-73.5c0-14.1-4.2-27.3-11.4-38.5-0.3 0-0.7-0.1-1.2-0.2z"/>
<path class="st1" d="m76.7 155.9l-9.6-4.2c-6.3-6.3-12.5-11.1-18.4-14.4-1.8-0.6-4.8-0.7-1.6 4s6.4 9.8 5.1 11.5c-1.3 1.6-5-0.2-6.5 2.5-1 1.8 5.3 3.9 18.9 6.4l7.1 5.5 5-11.3z"/>
<path class="st1" d="m296.9 245c-8.2-3.5-20.3-10.2-22.2-12.8-0.1-0.2-0.2-0.3-0.4-0.6v0.2l-13.4 2.6c0.8 1.4 1.3 2.3 1.5 2.6 3.4 5.2 0.2 14.7-0.9 19.7 7.3 1.7 4.4-9.3 13.6-7 8.4 2.1 15.5 7.4 23.4 0.2 0.9-0.7 1.6-3.5-1.6-4.9z"/>
<path class="st1" d="m43.5 330.5c-1 5.8-2 11.7-3 17.9l1.1 2.6c2.9 1.4 5.3 2 7.1 2 1.4 0 3.4-0.6 5.8-1.7 2.8-6.3 5.4-12.4 7.8-18.2l-18.8-2.6z"/>
<path d="m162.5 35.7c3.2-0.5 11.7-0.9 13.3-0.9 1 0 2.1 0.2 2.7-0.7 0.5-0.8 0.3-2 0.5-2.9 0.2-1 0.4-2 0.7-3 0.7-2.9 1.7-5.6 3.2-8.1 2.7-4.8 6.7-8.5 11.8-10.7 5.6-2.4 11.9-3 17.9-2.5 6.4 0.5 12.3 2.6 18.3 4.6 5.8 1.9 11.9 2.7 17.9 1.7 6.1-0.9 10.7-4.2 15.9-7.4 5.3-3.2 11.3-5.3 17.5-4.8 5.8 0.5 11.2 2.9 16 6.1 4.5 3 8.2 6.6 10.3 11.7 2.3 5.5 2.3 11.5-1.5 16.3-5.4 7-15.4 8.8-23.7 8.6-4.8-0.1-9.5-1-14.1-2.6-6-2.1-11.3-5.7-16.9-8.7-2.7-1.4-5.4-2.5-8.3-3.3-2.8-0.8-5.5-1.1-8.1 0.5-2.7 1.8-5.1 3.5-8.1 4.7-2.9 1.1-6 1.7-9.2 1.6-6.1 0-12.1-1.9-18-3.2-4.8-1-10-2.1-14.9-1.1-2 0.4-5 1.3-5.8 3.4 12.5 2.3 17.3 17.7 13.8 29-1.9 6.1-13.3 13.2-17.7 10-2.8-2-1.1-4.7 0-6.5 2.1-3.4 2.8-7.7-1.1-10.1-3.4-2-6.9 2.8-7 2.8-0.9-0.1-8.9-4.6-14.4-11.2-5.4 0.3-6.3-3.3-5-6.2s9.6-6.4 14-7.1z"/>
<path class="st1" d="m165.7 92.7l9.7-1.5 0.3-1.2h6.5l-3.2-15c-1.1 0-2.1-0.2-2.8-0.8-2.8-2-1.1-4.7 0-6.5 2.1-3.4 2.8-7.7-1.1-10.1-3.4-2-6.9 2.8-7 2.8-0.9-0.1-8.9-4.6-14.4-11.2-2.4 0.1-3.8-0.5-4.7-1.4-0.1 0.1-0.1 0.2-0.2 0.3-5.8 10.8-5.1 38.2 2.4 40.1 3 0.8 9.3-1.1 15.8-5l-1.3 9.5z"/>
</svg>

</template>

<script>
export default {
  props: ['hex'],
};
</script>

<style lang="scss" scoped>
.st0{opacity:0.2;}
.st1{opacity:0.41;}
.st2{opacity:0.8;}
.st3{opacity:0.9;}
.st4{opacity:0.5;}
.st5{opacity:0.3;}
.st6{opacity:0.85;}
.st7{opacity:0.298;}
</style>
