<template>
  <svg version="1.1"
       viewBox="0 0 377.8 163.9"
       xml:space="preserve"
       xmlns="http://www.w3.org/2000/svg"
       v-bind:fill="color">
    <path d="m0 42.7c0-4.9 0.9-9.9 2.8-14.8s4.6-9.3 8.1-13.1c3.5-3.9 7.9-7 13-9.4s10.9-3.6 17.4-3.6c7.7 0 14.4 1.7 20.1 5s9.9 7.6 12.7 13l-14.7 10.2c-0.9-2.2-2.1-4-3.5-5.4s-3-2.6-4.7-3.5-3.4-1.5-5.2-1.9c-1.8-0.3-3.5-0.5-5.2-0.5-3.6 0-6.8 0.7-9.5 2.2s-4.9 3.4-6.7 5.7-3.1 4.9-3.9 7.9c-0.9 2.9-1.3 5.9-1.3 8.9 0 3.2 0.5 6.4 1.5 9.4s2.4 5.7 4.3 8 4.2 4.2 6.8 5.5c2.7 1.4 5.7 2 9 2 1.7 0 3.5-0.2 5.3-0.6s3.5-1.1 5.2-2 3.2-2 4.6-3.5c1.4-1.4 2.5-3.2 3.4-5.3l15.5 9.1c-1.2 3-3.1 5.7-5.5 8.1s-5.2 4.4-8.4 6-6.5 2.9-10.1 3.7-7 1.3-10.4 1.3c-5.9 0-11.4-1.2-16.4-3.6s-9.3-5.7-12.9-9.7-6.4-8.6-8.3-13.7-3-10.1-3-15.4z" />
    <path d="m111.9 85.7c-5.2 0-9.8-0.9-13.8-2.5-4.1-1.7-7.5-4-10.2-6.9-2.8-2.9-4.9-6.3-6.3-10.1s-2.1-7.8-2.1-12 0.7-8.2 2.1-12 3.5-7.2 6.3-10.1 6.2-5.2 10.2-6.9c4.1-1.7 8.7-2.6 13.8-2.6 5.2 0 9.8 0.9 13.8 2.6s7.4 4.1 10.2 6.9c2.8 2.9 4.9 6.3 6.4 10.1s2.2 7.8 2.2 12-0.7 8.2-2.1 12-3.5 7.2-6.3 10.1-6.2 5.2-10.2 6.9c-4.3 1.6-8.9 2.5-14 2.5zm-13.6-31.5c0 4.8 1.3 8.6 3.8 11.5s5.8 4.3 9.7 4.3c1.9 0 3.7-0.4 5.3-1.2s3-1.9 4.2-3.2c1.2-1.4 2.1-3.1 2.8-5 0.7-2 1-4.1 1-6.4 0-4.8-1.3-8.6-3.8-11.5s-5.8-4.3-9.6-4.3c-1.9 0-3.7 0.4-5.4 1.2s-3.1 1.9-4.3 3.2c-1.2 1.4-2.1 3.1-2.8 5-0.5 1.9-0.9 4.1-0.9 6.4z" />
    <path d="m153.3 0h18.5v62.9c0 4.6 2 6.9 5.9 6.9 0.9 0 1.9-0.1 3.1-0.4 1.1-0.3 2.1-0.7 3.1-1.2l2.4 13.9c-2.3 1.1-5 1.9-8 2.5s-5.8 0.9-8.5 0.9c-5.3 0-9.4-1.4-12.3-4.2s-4.3-6.8-4.3-12v-69.3z" />
    <path d="m221.3 85.7c-5.2 0-9.8-0.9-13.8-2.5-4.1-1.7-7.5-4-10.2-6.9-2.8-2.9-4.9-6.3-6.3-10.1s-2.1-7.8-2.1-12 0.7-8.2 2.1-12 3.5-7.2 6.3-10.1 6.2-5.2 10.2-6.9c4.1-1.7 8.7-2.6 13.8-2.6 5.2 0 9.8 0.9 13.8 2.6s7.4 4.1 10.2 6.9c2.8 2.9 4.9 6.3 6.4 10.1s2.2 7.8 2.2 12-0.7 8.2-2.1 12-3.5 7.2-6.3 10.1-6.2 5.2-10.2 6.9c-4.2 1.6-8.9 2.5-14 2.5zm-13.6-31.5c0 4.8 1.3 8.6 3.8 11.5s5.8 4.3 9.7 4.3c1.9 0 3.7-0.4 5.3-1.2s3-1.9 4.2-3.2c1.2-1.4 2.1-3.1 2.8-5 0.7-2 1-4.1 1-6.4 0-4.8-1.3-8.6-3.8-11.5s-5.8-4.3-9.6-4.3c-1.9 0-3.7 0.4-5.4 1.2s-3.1 1.9-4.3 3.2c-1.2 1.4-2.1 3.1-2.8 5-0.5 1.9-0.9 4.1-0.9 6.4z" />
    <path d="m301.5 39.6c-4.5 0.1-8.5 0.8-12.2 2.2-3.6 1.4-6.3 3.5-7.9 6.3v36.5h-18.4v-60.9h17v12.3c2.1-4.1 4.8-7.3 8-9.6 3.3-2.3 6.8-3.5 10.5-3.6h1.9c0.4 0 0.8 0 1.2 0.1v16.7z" />
    <path d="m43.4 117.8c-4.5 0.1-8.5 0.8-12.2 2.2-3.6 1.4-6.3 3.5-7.9 6.3v36.5h-18.5v-60.8h17v12.3c2.1-4.1 4.8-7.3 8-9.6 3.3-2.3 6.8-3.5 10.5-3.6h1.9c0.4 0 0.8 0 1.2 0.1v16.6z" />
    <path d="m80.6 163.9c-5.1 0-9.7-0.8-13.7-2.5-4.1-1.7-7.5-3.9-10.3-6.8s-5-6.2-6.4-9.9c-1.5-3.7-2.2-7.6-2.2-11.7 0-4.4 0.7-8.5 2.2-12.4s3.6-7.3 6.4-10.2 6.2-5.2 10.2-6.9c4.1-1.7 8.7-2.5 13.8-2.5 5.2 0 9.8 0.9 13.8 2.5 4 1.7 7.4 4 10.2 6.9s4.9 6.2 6.3 10 2.1 7.8 2.1 11.9c0 1.1 0 2.1-0.1 3.2-0.1 1-0.2 2-0.3 2.7h-44.9c0.3 4.1 1.8 7.2 4.5 9.3s5.7 3.2 9.1 3.2c2.7 0 5.3-0.7 7.8-2s4.2-3.1 5-5.3l15.7 4.4c-2.4 4.8-6.1 8.7-11.1 11.7-5 2.9-11 4.4-18.1 4.4zm12.9-37.3c-0.4-3.9-1.8-6.9-4.2-9.1s-5.4-3.4-8.9-3.4c-3.6 0-6.6 1.1-9 3.4s-3.7 5.3-4.1 9.1h26.2z" />
    <path d="m135.2 162.7l-21.5-60.8h19.1l13.3 46 13.4-46h17.3l-21.4 60.8h-20.2z" />
    <path d="m185.2 162.7v-30.2l18.5-5.6v35.8h-18.5z" />
    <path d="m185.2 126.3v-24.7h18.5v19.1l-18.5 5.6z" />
    <path d="m246.5 163.9c-5.1 0-9.7-0.8-13.7-2.5-4.1-1.7-7.5-3.9-10.3-6.8s-5-6.2-6.4-9.9c-1.5-3.7-2.2-7.7-2.2-11.8 0-4.4 0.7-8.5 2.2-12.4s3.6-7.3 6.4-10.2 6.2-5.2 10.2-6.9c4.1-1.7 8.7-2.5 13.8-2.5 5.2 0 9.8 0.9 13.8 2.5 4 1.7 7.4 4 10.2 6.9s4.9 6.2 6.3 10 2.1 7.8 2.1 11.9c0 1.1 0 2.1-0.1 3.2-0.1 1-0.2 2-0.3 2.7h-44.9c0.3 4.1 1.8 7.2 4.5 9.3s5.7 3.2 9.1 3.2c2.7 0 5.3-0.7 7.8-2s4.2-3.1 5-5.3l15.7 4.4c-2.4 4.8-6.1 8.7-11.1 11.7-5.1 3-11.1 4.5-18.1 4.5zm12.9-37.3c-0.4-3.9-1.8-6.9-4.2-9.1s-5.4-3.4-8.9-3.4c-3.6 0-6.6 1.1-9 3.4s-3.7 5.3-4.1 9.1h26.2z" />
    <path d="m360.2 101.9h17.6l-25.1 60.8h-15.2l-9.4-25-9.4 25h-15.2l-25.1-60.8h17.5l16.6 43.7 5.7-16.6-10.8-27.1h14.8l5.9 18.3 5.8-18.3h14.8l-10.6 27.1 5.7 16.6 16.4-43.7z" />
    <circle cx="308.2"
            cy="79"
            r="8" /></svg>
</template>

<script>
export default {
  props: ['color'],
};
</script>

<style lang="scss">
</style>
