/* eslint-disable  */
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import touch from './touch-directives'
import router from './router.js'

Vue.config.productionTip = false;

touch.init(Vue)


new Vue({
  store,
  router,
  render: h => h(App),
  mounted () {
  }
}).$mount('#app');


window.addEventListener('touchstart', function onFirstTouch() {
  window.USER_IS_TOUCHING = true
}, false);

setTimeout(()=> {
  document.dispatchEvent(new Event('render-event'))
}, 1000)

