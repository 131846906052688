import Color from 'color';
import ColorParse from 'color-parse'

/* eslint-dsiable */
let createHSLString = (colorObject) => {
  let hsl = colorObject.color.hsl().array()
  hsl.forEach((element, index) => {
    hsl[index] = element % 1 === 0 ? element : element.toFixed(1)
  });
  hsl[0] = Math.round(hsl[0])
  return 'hsl(' + hsl[0] + ', ' + hsl[1] + '%, ' + hsl[2] + '%)'
}

let createRGBtring = (colorObject) => {
  let rgb = colorObject.color.rgb().array()
  rgb.forEach((element, index) => {
    rgb[index] = Math.round(element)
  });
  return 'rgb(' + rgb[0] + ', ' + rgb[1] + ', ' + rgb[2] + ')'
}

let parseUserString = (value) => {
  let p = ColorParse(value)
  if (!p.values || !Array.isArray(p.values)) throw 'Could not convert ' + value

  p.values.forEach((element) => {
    // eslint-disable-next-line
    if (isNaN(element)) throw 'Could not convert ' + value
  }) 

  if (p.space === 'hsl') {
    return {
      mode: 'hsl',
      value: p.values
    }
  } if (p.space === 'rgb') {
    return {
      mode: 'rgb',
      value: p.values
    }
  } 

  throw 'Could not convert ' + value
}

let parseUrlCheck = (value) => {
  value = value.split('-')
  if (!Array.isArray(value) || value.length !== 2) return null
  let foreground = ColorParse('#' + value[0])
  let background = ColorParse('#' + value[1])
  if (foreground.space !== 'rgb' || background.space !== 'rgb') return null
  return [foreground.values, background.values]
}

export default {
  createHSLString,
  createRGBtring,
  parseUserString,
  parseUrlCheck
};
