import Vue from 'vue'
import Vuex from 'vuex'
import Color from 'color'
import { convert, parse } from 'pure-color'
import createPersistedState from 'vuex-persistedstate'
import ColorHelper from './tools/ColorHelper'

Vue.use(Vuex);

window.convert = convert
window.parse = parse

// eslint-disable-next-line func-names
let cleanColorObject = function (co) {
  co.color.forEach((e, index) => {
    co.color[index] = Number.isNaN(e) ? 0 : e
  });
  return co
}

// eslint-disable-next-line func-names
let colorObject = function (c) {
  let { model } = c
  let { value } = c

  if (model === 'hex') {
    value = parse(value)
    model = 'rgb'
    // parse will sometimes give an array with 4 values, remove if so
    value.length = 3
  }

  let co = Color[model](value)
  co = cleanColorObject(co)

  return {
    color: co,
    original: c
  }
}

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['picker']
  })],
  state: {
    colors: {
      foreground: {
        value: [209, 40, 27],
        model: 'hsv'
      },
      background: {
        value: [145, 0, 100],
        model: 'hsv'
      },
    },
    picker: {
      mode: 'rgb',
      selected: 'foreground',
      showColorModes: ['rgb'],
      mobileColorMode: 'hex'
    }
  },
  getters: {
    selected(state) {
      return colorObject(state.colors[state.picker.selected])
    },
    notSelected(state) {
      let s = state.picker.selected === 'background' ? 'foreground' : 'background'
      return colorObject(state.colors[s])
    },
    foreground(state) {
      return colorObject(state.colors.foreground)
    },
    background(state) {
      return colorObject(state.colors.background)
    },
    contrast(state) {
      return colorObject(state.colors.foreground).color.contrast(colorObject(state.colors.background).color)
    },
    contrastFixed(state, getters) {
      function toFixedCeil(num, fixed) {
        let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
        if (!num) return null
        return num.toString().match(re)[0];
      }
      return toFixedCeil(
        colorObject(state.colors.foreground).color.contrast(colorObject(state.colors
          .background).color), 1
      )
    },
    interfaceColor(state) {
      // return Color.rgb('#102344')
      return Color.hsv([0, 0, 0])
    },
    colorInFormat: (state, getters) => (mode, target) => {
      let c = colorObject(state.colors[target])
      if (c.original.model === mode) {
        return c.original.value
      }
      
      let newColor = {}
      if (mode === 'hslCss') {
        newColor = ColorHelper.createHSLString(c)
      } else if (mode === 'rgbCss') {
        newColor = ColorHelper.createRGBtring(c)
      } else {
        newColor = c.color[mode]()
        newColor = typeof (newColor) === 'object' ? newColor.array() : newColor
      }

      return newColor
    },
    // eslint-disable-next-line
    showColorMode: state => (mode) => {
      return state.picker.showColorModes.includes(mode)
    },
    mobileColorMode: state => state.picker.mobileColorMode
  },
  mutations: {
    setHSVColor(state, payload) {
      let co = colorObject(state.colors[state.picker.selected]).color

      if (co.model !== 'hsv') {
        co = co.hsv()
        Vue.set(state.colors[state.picker.selected], 'model', 'hsv')
      }

      let hue = payload.hue ? payload.hue : co.hue()
      if (hue < 0) payload.hue = 0
      if (hue > 360) payload.hue = 360

      let arr = co.array()

      if (!payload.saturation && !payload.brightness) {
        arr[0] = hue
      } else {
        arr[1] = payload.saturation
        arr[2] = payload.brightness
      }

      Vue.set(state.colors[state.picker.selected], 'value', arr)
    },
    setColor(state, payload) {
      state.colors[payload.target].value = payload.value
      if (!payload.mode) return
      state.colors[payload.target].model = payload.mode
    },
    setSelected(state, payload) {
      state.picker.selected = payload
    },
    toggleColorMode(state, mode) {
      if (state.picker.showColorModes.includes(mode)) {
        state.picker.showColorModes = state.picker.showColorModes.filter(item => item !== mode)
      } else {
        state.picker.showColorModes.push(mode)
      }
    },
    setMobileColorMode(state, payload) { state.picker.mobileColorMode = payload }
  },
  actions: {
    flipColors({ commit, state }) {
      let { foreground } = JSON.parse(JSON.stringify(state.colors))
      // state.colors.foreground = state.colors.background
      // state.colors.background = foreground
      Vue.set(state.colors, 'foreground', state.colors.background)
      Vue.set(state.colors, 'background', foreground)
      // let s = 'background'
      // if (state.colors.selected === s) s = 'foreground'
      // commit('setSelected', s)
    }
  },
});
