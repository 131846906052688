<template>
  <div v-if="show" class="browser-warning">
    This site may not work fully in your browser, please upgrade to a modern version such as <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">Microsoft Edge</a> or 
    <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>. 
  </div>
</template>

<script>

function isIE() {
  let ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
  let msie = ua.indexOf('MSIE '); // IE 10 or older
  let trident = ua.indexOf('Trident/'); // IE 11

  return (msie > 0 || trident > 0);
}


export default {
  data() {
    return {
      show: false
    }
  },
  mounted() {
    if (isIE()) this.show = true
  }
};
</script>

<style lang="scss" scoped>
.browser-warning {
  width: 100%;
  text-align: center;
  padding: 8px;
  font-size: 12px;
}

</style>
