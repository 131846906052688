<template>
  <div class="color-selector-mobile">

    <div class="color-values" v-bind:class="'color-input-' + colorMode">
      <div class="color-value">
        <span class="c-label">Foreground</span> 
        <color-input :mode="colorMode" target="foreground"/>
      </div>
      <div class="color-value">
        <span class="c-label">Background</span>
        <color-input :mode="colorMode" target="background"/>
      </div>
    </div>

    <div class="color-dropdown hide-on-desktop">
      <dropdown-menu :active="colorMode" :options="colorModes" @change="changeColorMode"/>
    </div>
  </div>
</template>
<script>
import Color from 'color'
import ColorInput from './ColorInput.vue'
import DropdownMenu from '../DropdownMenu.vue'

export default {
  components: { ColorInput, DropdownMenu },
  mounted() {},
  data() {
    return {
      colorModes: [
        { value: 'hex', label: 'hex' },
        { value: 'rgb', label: 'rgb' },
        { value: 'hsl', label: 'hsl' },
        { value: 'cmyk', label: 'cmyk' },
      ]
    };
  },
  computed: {
    activeColor() {
      return this.target === 'foreground'
        ? this.$store.getters.foreground
        : this.$store.getters.background;
    },
    reverseColor() {
      return this.target === 'foreground'
        ? this.$store.getters.background
        : this.$store.getters.foreground;
    },
    colorMode() {
      return this.$store.getters.mobileColorMode
    }
  },
  methods: {
    hasGlobalFocus() {
      return this.$store.state.picker.selected === this.target;
    },
    setSelected() {
      this.$store.commit('setSelected', this.target);
    },
    showColorMode(mode) {
      return this.$store.getters.showColorMode(mode)
    },
    changeColorMode(mode) {
      this.$store.commit('setMobileColorMode', mode)
    }
  },
  watch: {},
};
</script>

<style lang="scss">
.color-selector-mobile {
  font-size: 11px;
  align-items: center;
  margin-bottom: 4px;
  margin-top: -6px;
  
  .color-values {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1.8em;
    .color-value {
      display: flex;
      align-items: center;
    }
    .color-value:first-of-type {
      margin-right: 8px;
    }
  }
  .c-label {
    font-weight: 600;
    display: inline-block;
    margin-right: 2px;
    width: 5.7em;
  }

  .color-dropdown {
    max-width: 98px;
    width: 100%;
    min-width: 60px;
    display: flex;
    .dropdown-menu {
      width: 60px;
    }
  }

  .color-input {
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    .color-input-field {
      label {
        padding-left: 0px;
        padding-right: 4px;
        font-size: 10px;
        width: 15em;
      }
      input {
        border-bottom: 1px solid #ccc;
        font-size: 11px;
        margin-right: 8px;
      }
      input:focus {
        border-bottom: 1px solid #666;
      }
    }
  }
  .color-input-hex {
    .c-label {
      width: auto;
    }
    label {
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 11px;
    }
    input {
     margin-right: 0px !important;

    }
  }

}
</style>
