<template>
  <div>
    <!-- aa -->
    <svg v-if="contrast >= target && type === 'AA'" width="46px" height="14px" viewBox="0 0 46 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="aa" :fill="hex">
            <path d="M34.7773438,2.8046875 C35.9804748,1.60155648 37.429679,1 39.125,1 C40.820321,1 42.264968,1.59699924 43.4589844,2.79101562 C44.6530008,3.98503201 45.25,5.42967902 45.25,7.125 C45.25,8.82032098 44.6530008,10.264968 43.4589844,11.4589844 C42.264968,12.6530008 40.820321,13.25 39.125,13.25 C37.429679,13.25 35.985032,12.6530008 34.7910156,11.4589844 C33.5969992,10.264968 33,8.82032098 33,7.125 C33,5.42967902 33.592442,3.98958926 34.7773438,2.8046875 Z M42.2695312,5.07421875 C42.2877605,5.05598949 42.296875,5.02864602 42.296875,4.9921875 C42.296875,4.93749973 42.2877605,4.90104176 42.2695312,4.8828125 L41.421875,4.2265625 C41.4036457,4.20833324 41.3671878,4.19921875 41.3125,4.19921875 C41.2760415,4.19921875 41.248698,4.20833324 41.2304688,4.2265625 L38.1953125,8.13671875 C37.3932252,7.37108992 36.983073,6.97916676 36.9648438,6.9609375 C36.910156,6.90624973 36.855469,6.87890625 36.8007812,6.87890625 C36.782552,6.87890625 36.746094,6.90624973 36.6914062,6.9609375 L35.9804688,7.671875 C35.9622395,7.69010426 35.953125,7.72656223 35.953125,7.78125 C35.953125,7.81770852 35.9622395,7.84505199 35.9804688,7.86328125 L36.0351562,7.890625 C37.4570384,9.25781934 38.1770832,9.95052074 38.1953125,9.96875 C38.2500003,10.0234378 38.2955727,10.0507812 38.3320312,10.0507812 C38.3684898,10.0507812 38.4140622,10.0234378 38.46875,9.96875 L42.2695312,5.07421875 Z" id="ion-checkmark-circled---Ionicons-Copy-7"></path>
            <path d="M12.506,3 L13.155,3 L16.4,10.81 L15.586,10.81 L14.574,8.368 L11.065,8.368 L10.064,10.81 L9.23900003,10.81 L12.506,3 Z M14.398,7.763 L12.825,3.902 L11.23,7.763 L14.398,7.763 Z M20.7450001,3 L21.3940001,3 L24.6390001,10.81 L23.8250001,10.81 L22.8130001,8.368 L19.3040001,8.368 L18.3030001,10.81 L17.4780001,10.81 L20.7450001,3 Z M22.6370001,7.763 L21.0640001,3.902 L19.4690001,7.763 L22.6370001,7.763 Z" id="AAA-Copy-4"></path>
        </g>
    </svg>

    <!-- aa-no -->
    <svg v-if="contrast < target && type === 'AA'" width="46px" height="14px" viewBox="0 0 46 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="aa-no"  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="1">
            <path :stroke="hex" d="M9,8 L25,8" id="Line"  stroke-linecap="square"></path>
            <path :fill="hex" d="M12.506,3 L13.155,3 L16.4,10.81 L15.586,10.81 L14.574,8.368 L11.065,8.368 L10.064,10.81 L9.23900003,10.81 L12.506,3 Z M14.398,7.763 L12.825,3.902 L11.23,7.763 L14.398,7.763 Z M20.7450001,3 L21.3940001,3 L24.6390001,10.81 L23.8250001,10.81 L22.8130001,8.368 L19.3040001,8.368 L18.3030001,10.81 L17.4780001,10.81 L20.7450001,3 Z M22.6370001,7.763 L21.0640001,3.902 L19.4690001,7.763 L22.6370001,7.763 Z" id="AAA-Copy-3" ></path>
        </g>
    </svg>

    <!-- aaa -->
    <svg v-if="contrast >= target && type === 'AAA'" width="46px" height="14px" viewBox="0 0 46 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="aaa" :fill="hex" stroke="none" stroke-width="1"  fill-rule="evenodd">
            <path d="M5.267,3 L5.916,3 L9.161,10.81 L8.347,10.81 L7.335,8.368 L3.826,8.368 L2.825,10.81 L2,10.81 L5.267,3 Z M7.159,7.763 L5.586,3.902 L3.991,7.763 L7.159,7.763 Z M13.506,3 L14.155,3 L17.4,10.81 L16.586,10.81 L15.574,8.368 L12.065,8.368 L11.064,10.81 L10.239,10.81 L13.506,3 Z M15.398,7.763 L13.825,3.902 L12.23,7.763 L15.398,7.763 Z M21.7450001,3 L22.3940001,3 L25.6390001,10.81 L24.8250001,10.81 L23.8130001,8.368 L20.3040001,8.368 L19.3030001,10.81 L18.4780001,10.81 L21.7450001,3 Z M23.6370001,7.763 L22.0640001,3.902 L20.4690001,7.763 L23.6370001,7.763 Z" id="AAA-Copy-2"></path>
            <path d="M34.7773438,2.8046875 C35.9804748,1.60155648 37.429679,1 39.125,1 C40.820321,1 42.264968,1.59699924 43.4589844,2.79101562 C44.6530008,3.98503201 45.25,5.42967902 45.25,7.125 C45.25,8.82032098 44.6530008,10.264968 43.4589844,11.4589844 C42.264968,12.6530008 40.820321,13.25 39.125,13.25 C37.429679,13.25 35.985032,12.6530008 34.7910156,11.4589844 C33.5969992,10.264968 33,8.82032098 33,7.125 C33,5.42967902 33.592442,3.98958926 34.7773438,2.8046875 Z M42.2695312,5.07421875 C42.2877605,5.05598949 42.296875,5.02864602 42.296875,4.9921875 C42.296875,4.93749973 42.2877605,4.90104176 42.2695312,4.8828125 L41.421875,4.2265625 C41.4036457,4.20833324 41.3671878,4.19921875 41.3125,4.19921875 C41.2760415,4.19921875 41.248698,4.20833324 41.2304688,4.2265625 L38.1953125,8.13671875 C37.3932252,7.37108992 36.983073,6.97916676 36.9648438,6.9609375 C36.910156,6.90624973 36.855469,6.87890625 36.8007812,6.87890625 C36.782552,6.87890625 36.746094,6.90624973 36.6914062,6.9609375 L35.9804688,7.671875 C35.9622395,7.69010426 35.953125,7.72656223 35.953125,7.78125 C35.953125,7.81770852 35.9622395,7.84505199 35.9804688,7.86328125 L36.0351562,7.890625 C37.4570384,9.25781934 38.1770832,9.95052074 38.1953125,9.96875 C38.2500003,10.0234378 38.2955727,10.0507812 38.3320312,10.0507812 C38.3684898,10.0507812 38.4140622,10.0234378 38.46875,9.96875 L42.2695312,5.07421875 Z" id="ion-checkmark-circled---Ionicons-Copy-5"></path>
        </g>
    </svg>

    <!-- aaa-no -->
  <svg style="transform: translateX(1px)" v-if="contrast < target && type === 'AAA'" width="46px" height="14px" viewBox="0 0 46 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="aaa-no" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="1">
            <path :stroke="hex"  d="M1,8 L25,8" id="Line" stroke-linecap="square"></path>
            <path :fill="hex"  d="M4.267,3 L4.916,3 L8.161,10.81 L7.347,10.81 L6.335,8.368 L2.826,8.368 L1.825,10.81 L1,10.81 L4.267,3 Z M6.159,7.763 L4.586,3.902 L2.991,7.763 L6.159,7.763 Z M12.506,3 L13.155,3 L16.4,10.81 L15.586,10.81 L14.574,8.368 L11.065,8.368 L10.064,10.81 L9.23900003,10.81 L12.506,3 Z M14.398,7.763 L12.825,3.902 L11.23,7.763 L14.398,7.763 Z M20.7450001,3 L21.3940001,3 L24.6390001,10.81 L23.8250001,10.81 L22.8130001,8.368 L19.3040001,8.368 L18.3030001,10.81 L17.4780001,10.81 L20.7450001,3 Z M22.6370001,7.763 L21.0640001,3.902 L19.4690001,7.763 L22.6370001,7.763 Z" id="AAA-Copy-3"></path>
        </g>
    </svg>
  </div>

</template>

<script>
export default {
  props: ['contrast', 'target', 'type', 'hex'],
};
</script>

<style lang="scss">

</style>
