<template>
  <article v-bind:style="{ color: hex}">

    <!-- <lights :hex="hex" v-show="background !== '#FFFFFF'" class="lights"/> -->
    <!-- <lights :hex="hex" class="lights"/> -->
    <div class="row">
      <h1 class="main">What you need to know about colors &amp; accessibility.</h1>
      <aside style="align-items: flex-end; padding-bottom: 10px;">
        <div>
          <a-a-level :contrast="contrast"
                     :target="3"
                     type="AA"
                     :hex="hex" />
          <a-a-level :contrast="contrast"
                     :target="4.5"
                     type="AAA"
                     :hex="hex" />
        </div>
      </aside>
    </div>
    <div class="row">
      <div class="main">
        <girl :hex="hex"
              class="girl" />

        <p>
          We experience contrast differently in different colors. Because of
          how our eyes work, blue text on a white background will be much more
          easily read than a yellow. When you are designing for humans, in
          whatever medium, this is something to always keep in mind. Even more
          importantly, an estimated 217 million people live with a visual
          impairment. To help make sure that your work can be used and read by
          as many people as possible, always keep an eye on the relative
          contrast between your colors. This makes sure that everyone has a
          chance to view your content, including those with color blindness.
        </p>
        <p>
          For digital work, the WCAG standard has been put together for this
          purpose and is what this site uses (the latest 2.1 version). It has
          two levels, AA and the more strict AAA.
        </p>

        <p class="big-quote"
           v-bind:style="{ borderLeftColor: hex }">
          For <strong>AA</strong> the required contrast for <strong>text</strong>
          is <strong>4.5</strong>. <strong>Headlines and large text</strong>
          needs at least <strong>3</strong>.
        </p>
        <p class="small-text">
          Large text is defined as 14 point (typically 18.66px) and bold or
          larger, or 18 point (typically 24px) or larger. You can read the
          full WCAG 2.1 standard <a href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
             target="_blank">here</a>. The current contrast is <strong>{{
            contrast }}</strong>.
        </p>
        <p class="big-quote"
           v-bind:style="{ borderLeftColor: hex }">
          <strong>AAA</strong> requires <strong>7</strong> for <strong>text</strong>.
          For <strong>headlines and large text</strong> the minimum is
          <strong>4.5</strong>.
        </p>

      </div>

      <aside style="padding-top: 20px;">
        <div>
          <a-a-level :contrast="contrast"
                     :target="4.5"
                     type="AA"
                     :hex="hex" />
          <a-a-level :contrast="contrast"
                     :target="7"
                     type="AAA"
                     :hex="hex" />
        </div>
      </aside>
    </div>

    <div class="row"
         style="margin-top: 0px;">

      <div class="main">
        <p>

          <span class="line-break-on-mobile"
                style="padding-right: 10px">Change background</span>
          <button class="button button-full"
                  @click="setBackgroundRandom"
                  v-bind:style="{
              borderColor: hex,
              background: hex,
              color: background
              }">Random
            color
          </button>

          <button class="button button-outline"
                  @click="setBackgroundWhite"
                  v-bind:style="{
              borderColor: hex,
              color: hex
              }">White</button>
        </p>
        <p class="small-text"
           style="margin-top: -1em">
          Interface elements that are not currently active, such as buttons
          &amp; sliders, requires a contrast of at least <strong>3</strong>.
        </p>

        <p>
          Direct link <a :href="pageLinkFull">{{pageLink}}</a>
        </p>
        
    
      </div>

      <aside style="padding-top: 22px;">
        <div>
          <a-a-level :contrast="contrast"
                     :target="3"
                     type="AA"
                     :hex="hex" />
        </div>
      </aside>
    </div>

  </article>
</template>
<script>
import AALevel from './AALevel.vue';
import Girl from './Girl.vue';

export default {
  props: ['hex'],
  components: { AALevel, Girl },
  computed: {
    contrast() {
      return Number(this.$store.getters.contrastFixed);
    },
    background() {
      return this.$store.getters.background.color.hex();
    },
    pageLink() {
      let fhex = this.$store.getters.foreground.color.hex().substring(1);
      let bhex = this.$store.getters.background.color.hex().substring(1);
      return 'color.review/check/' + fhex + '-' + bhex;
    },
    pageLinkFull() {
      return 'https://' + this.pageLink;
    },
  },
  methods: {
    setBackgroundWhite() {
      this.$store.commit('setColor', {
        target: 'background',
        value: '#FFFFFF',
        mode: 'hex',
      });
    },
    setBackgroundRandom() {
      // eslint-disable-next-line
      let value = '#' + ((Math.random() * 0xffffff) << 0).toString(16);
      this.$store.commit('setColor', {
        target: 'background',
        value,
        mode: 'hex',
      });
    },
  },
};
</script>
