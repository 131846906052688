<template>
  <div>
    <browserstack-header />
    <div class="top">
      <div class="inner">
        <a href="https://color.review">
          <logo class="logo-holder" :color="interfaceColor" />
        </a>
        <contrast />
        <div class="picker">
          <picker />
        </div>
        <color-selector-mobile class="show-on-mobile-flex" />
      </div>
    </div>

    <div class="bottom">
      <div class="bottom-tri">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 293 94"
          style="enable-background: new 0 0 293 94"
          xml:space="preserve"
          preserveAspectRatio="none"
          fill="white"
        >
          <polygon points="0,0 0,94 293,0 " />
        </svg>
      </div>

      <div class="bottom-bellow" v-bind:style="{ background: back }">
        <div class="content">
          <r-article :hex="hex" />
        </div>
      </div>
    </div>
    <offer :hex="hex" />
    <r-footer :hex="hex" />
  </div>
</template>

<script>
import Color from 'color';
import Picker from './components/picker/Picker.vue';
import RArticle from './components/Article.vue';
import Offer from './components/Offer.vue';
import RFooter from './components/Footer.vue';
import Logo from './components/Logo.vue';
import Contrast from './components/Contrast.vue';
import ColorSelectorMobile from './components/picker/ColorSelectorMobile.vue';
import ColorHelper from './tools/ColorHelper';
import BrowserstackHeader from './components/BrowserstackHeader.vue';

export default {
  components: {
    Picker,
    RArticle,
    Offer,
    RFooter,
    Logo,
    Contrast,
    ColorSelectorMobile,
    BrowserstackHeader,
  },
  data() {
    return {};
  },
  beforeMount() {
    if (this.$route.name === 'StartCheck' && this.$route.params.colors) {
      let result = ColorHelper.parseUrlCheck(this.$route.params.colors);
      if (result === null) {
        this.$router.push('/');
      } else {
        this.$store.commit('setColor', { target: 'foreground', value: result[0], mode: 'rgb' });
        this.$store.commit('setColor', { target: 'background', value: result[1], mode: 'rgb' });
      }
    }
  },
  methods: {},
  computed: {
    topBack() {
      let hsl = Color(this.hex).hsl().array();
      let newC = Color.hsl([hsl[0], hsl[1], 95]);

      let r =
        'linear-gradient(-5deg, ' +
        newC.alpha(0).string() +
        ' 52%, ' +
        newC.alpha(0.1).string() +
        ' 53%, ' +
        newC.alpha(0.1).string() +
        ' 100%' +
        ')';
      return r;
    },
    hex() {
      return this.$store.getters.foreground.color.hex();
    },
    back() {
      return this.$store.getters.background.color.hex();
    },
    interfaceColor() {
      return this.$store.getters.interfaceColor.hex();
    },
  },
};
</script>

<style lang="scss"></style>
