<template>
  <div class="color-input">
    <div class="color-input-field" v-for="(field, index) in colorMode.fields" v-bind:key="index">
      <label :for="'input-'+internalId+index">{{field.label}}</label>
      <input :id="'input-'+internalId+index" spellcheck="false" 
          :type="colorMode.type"
          :value="convertedAtIndex(index)"
          :maxlength="field.length"
          v-bind:style="{ width: colorMode.width + 'em'}"
          @input="update(index, $event)"
          @focus="onFocus"
      /> 
    </div>
  </div>
</template>
<script>
import Color from 'color';
import { convert } from 'pure-color'
import ColorModes from './ColorModes'
import ColorHelper from '../../tools/ColorHelper'

export default {
  components: {},
  props: ['target', 'mode'],
  mounted() {},
  data() {
    return {
      modes: ColorModes,
      internalId: Math.random().toString(36).substring(7)
    };
  },
  computed: {
    storeColor() {
      return this.$store.getters[this.target]
    },
    convertedColor() {
      return this.$store.getters.colorInFormat(this.mode, this.target)
    },
    colorMode() {
      return this.modes[this.mode]
    }
  },
  methods: {
    convertedAtIndex(index) {
      let value = Array.isArray(this.convertedColor) ? this.convertedColor[index] : this.convertedColor

      if (typeof (value) === 'string' && value.charAt(0) === '#') value = value.substr(1)

      if (this.colorMode.rounding === false) return value
      return value % 1 === 0 ? value : Number(value).toFixed(this.colorMode.rounding)
    },
    update(index, e) {
      let value = e.srcElement.value
      let mode = this.mode

      if (mode === 'hex') {
        if (value.charAt(0) !== '#') value = '#' + value
        if (value.length < 4) return
        if (value.length > 7) value = value.substring(0, 7)
      } else if (mode === 'hslCss' || mode === 'rgbCss') {
        let p = ColorHelper.parseUserString(value)
        if (!p) return 
        value = p.value
        mode = p.mode
      } else {
        value = Number(value)
        let max = this.colorMode.fields[index].max

        if (max && value > max) value = max
        else if (value < 0) value = 0

        // Add it to the correct place in array if it is an array type
        let newArr = this.convertedColor.slice(0)
        newArr[index] = Number(value)
        value = newArr
      }
      
      this.$store.commit('setColor', {
        target: this.target,
        value,
        mode
      })

      // hack for removing 6+ digits in hex
      if (this.mode === 'hex') this.$forceUpdate()
    },
    onFocus() {
      this.$store.commit('setSelected', this.target)
    }
  },
  watch: {},
};
</script>

<style lang="scss">
@import '../../settings';

.color-input {
    display: flex;
    margin-bottom: 6px;
    background: white;
    border: 1px solid $light-border-color;
    border-radius: 4px;
    padding: 1px 5px;

  .color-input-field {
    // margin-right: 6px;
    // padding-left: 4px;
    // padding-left: 2px;
    label {
      font-size: 12px;
      color: #999;
      font-weight: 500;
      padding-left: 2px;
      padding-right: 3px;
      // margin-left: 5px;
    }
    input {
      border: 0;
      border-radius: 0;
      // border-bottom: 1px solid #C4C4C4;
      width: 2em;
      padding: 2px;
      font-size: 12px;
      text-align: center;
      background: none;
    }
    input:focus {
      color: #000;
    }
  }
}

.color-input.small {
  label {
    display: none;
  }
  input {
    font-size: 10px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
</style>
