<template>
  <div class="contrast">
    <div class="contrast-left">
      <div class="title hide-on-very-small">
        The <strong>contrast</strong> between <br>
foreground and background is
      </div>
      <div class="title show-on-very-small" style="font-weight: 600">
        Contrast
      </div>
      <div class="value">{{value}}</div>
    </div>
    <div style="flex: 1"></div>
    <div class="contrast-right">
      <div class="block">
        <div class="type">Text</div>
        <a-a-level-inline :contrast="value" aa="4.5" aaa="7" />
        <!-- <img src="../assets/icons/line-fail.svg" alt="FAIL"> -->
      </div>
      <div class="block">
        <div class="type">Headlines</div>
        <a-a-level-inline :contrast="value" aa="3" aaa="4.5" />
        <!-- <img src="../assets/icons/line-aa.svg" alt="AA"> -->
      </div>
    </div>
    <div class="page-link hide-on-mobile">
      <a :href="pageLinkFull">
      <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.29866 0.702709C7.36147 -0.234236 5.84212 -0.234236 4.9052 0.702709L3.56626 2.04112C3.67225 2.02959 3.77935 2.02464 3.887 2.02464C4.22585 2.02464 4.55535 2.07847 4.86729 2.18225L5.626 1.4238C5.88659 1.16293 6.23313 1.01959 6.60165 1.01959C6.9702 1.01959 7.31674 1.16293 7.57733 1.4238C7.83793 1.68412 7.98122 2.03012 7.98122 2.39918C7.98122 2.76769 7.83793 3.11424 7.57733 3.37456L6.09285 4.85906C5.83228 5.11994 5.48572 5.26325 5.1172 5.26325C4.74813 5.26325 4.40214 5.11994 4.14129 4.85906C4.01442 4.73273 3.91558 4.58554 3.84747 4.42516C3.67831 4.43449 3.52016 4.5048 3.39933 4.62561L3.00391 5.02155C3.1121 5.222 3.25105 5.41093 3.42021 5.58067C4.35713 6.51765 5.87675 6.51765 6.81394 5.58067L8.29868 4.09563C9.23532 3.15874 9.23532 1.63965 8.29866 0.702709Z" fill="black" fill-opacity="0.7"/>
  <path d="M5.13012 6.97292C4.79071 6.97292 4.45791 6.91799 4.13886 6.8098L3.37492 7.57377C3.1146 7.83466 2.76807 7.97798 2.39956 7.97798C2.03104 7.97798 1.68506 7.83466 1.42417 7.57377C1.16331 7.31343 1.01996 6.96692 1.01996 6.59839C1.01996 6.22986 1.16331 5.8833 1.42417 5.62246L2.90865 4.13799C3.16952 3.87766 3.51552 3.73432 3.88403 3.73432C4.25309 3.73432 4.59909 3.87766 4.85966 4.13799C4.98655 4.26484 5.08562 4.41203 5.15404 4.57239C5.32375 4.5636 5.48219 4.49276 5.60302 4.37195L5.99789 3.97652C5.8897 3.7755 5.75047 3.58659 5.58105 3.41688C4.64412 2.47994 3.12451 2.47994 2.18758 3.41688L0.703104 4.90192C-0.234368 5.8389 -0.234368 7.35793 0.703104 8.29542C1.64003 9.2324 3.15912 9.2324 4.09602 8.29542L5.43275 6.95868C5.33308 6.96798 5.23231 6.97297 5.13069 6.97297L5.13012 6.97292Z" fill="black" fill-opacity="0.7"/>
  </svg> </a>
      {{pageLink}}
    </div>
  </div>
</template>

<script>
import AALevelInline from './AALevelInline.vue'

export default {
  components: { AALevelInline },
  computed: {
    value() {
      return Number(this.$store.getters.contrastFixed)
    },
    pageLink() {
      let fhex = this.$store.getters.foreground.color.hex().substring(1)
      let bhex = this.$store.getters.background.color.hex().substring(1)
      return 'color.review/check/' + fhex + '-' + bhex
    },
    pageLinkFull() {
      return 'https://' + this.pageLink
    }
  },
};
</script>

<style lang="scss">
.contrast {
  margin-top: 5px;
  margin-bottom: 8px;
  font-size: 13px;
  user-select: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  // margin-left: 40px;
  .contrast-left {
    // width: 200px;
    display: flex;
    align-items: flex-end;
    .title {
      align-items: center;
      color: #000;
      font-size: 11px;
    }
    .value {
      font-weight: 500;
      font-size: 24px;
      margin-left: 10px;
      transform: translateY(3px);
      user-select: all;
    }
  }
  .contrast-right {
    display: flex;
    .block {
      margin-left: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .type {
        margin-right: 8px;
        display: flex;
        font-weight: 600;
        font-size: 11px;
        align-items: center;
              color: #000;

      }
      .aa-inline {
        width: 50px;
        text-align: left;
      }
      svg {
        transform: translateY(4.6px);
        user-select: none;
        pointer-events: none;
      }
    }
  }
  .page-link {
    width: 260px;
    // background: red;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-right: 14px;
    padding-left: 10px;
    box-sizing: border-box;
    color: #757474;
    font-size: 11px;
    letter-spacing: 0.02em;
    user-select: all;
    svg {
      margin-right: 6px;
      transform: translateY(0.6px);
    }
  }
}
</style>
