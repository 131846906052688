<template>
  <div class="color-eye-dropper" v-if="browserSupport">
    <div @click="getColor()" class="eye-dropper-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.542 13.147l-7.455-6.667 1.334-1.49c.822.736 2.087.666 2.822-.159l3.503-3.831c.593-.663 1.414-1 2.238-1 1.666 0 3.016 1.358 3.016 2.996 0 .723-.271 1.435-.78 2.005l-3.503 3.83c-.735.824-.666 2.087.158 2.825l-1.333 1.491zm-4.314-1.175l-7.791 8.65c-.314.352-1.322.323-1.853.557.172-.554.048-1.538.362-1.89l7.791-8.651-1.491-1.333-7.9 8.794c-1.277 1.423-.171 2.261-1.149 4.052-.135.244-.197.48-.197.698 0 .661.54 1.151 1.141 1.151.241 0 .492-.079.724-.256 1.733-1.332 2.644-.184 3.954-1.647l7.9-8.792-1.491-1.333z"/></svg>
    </div>
  </div>
</template>
<script>
import Color from 'color'
import ColorInput from './ColorInput.vue'

export default {
  components: {  },
  props: ['target', 'label'],
  mounted() {
    if (window.EyeDropper) { 
      this.browserSupport = true
    }
  },
  data() {
    return {
      browserSupport: false
    };
  },
  computed: {
    activeColor() {
      return this.target === 'foreground'
        ? this.$store.getters.foreground
        : this.$store.getters.background;
    },
    reverseColor() {
      return this.target === 'foreground'
        ? this.$store.getters.background
        : this.$store.getters.foreground;
    },
  },
  methods: {
    getColor() {
      if (!window.EyeDropper) return
      const eyeDropper = new EyeDropper();
      const abortController = new AbortController();

      eyeDropper
        .open({ signal: abortController.signal })
        .then((result) => {
          console.log('got color', result.sRGBHex);
          this.$store.commit('setColor', {
            target: this.target,
            value: result.sRGBHex,
            mode: 'rgb'
          })
        })
        .catch((e) => {
          console.error('Could not pick color', e)
        });

      setTimeout(() => {
        abortController.abort();
      }, 1000 * 120);

    },
    hasGlobalFocus() {
      return this.$store.state.picker.selected === this.target;
    },
    setSelected() {
      this.$store.commit('setSelected', this.target);
    },
    showColorMode(mode) {
      return this.$store.getters.showColorMode(mode)
    }
  },
  watch: {},
};
</script>

<style lang="scss">
.color-eye-dropper {
  cursor: pointer;
  .eye-dropper-icon {
    padding: 6px 12px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}
</style>
