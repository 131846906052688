<template>
  <div class="offer-video" >
    <div class="graphics" v-bind:style="{ background } "></div>
    <video ref="video" 
           width="100%"
           poster="/video/screen-a-full.jpg"
           preload="none"
           muted
           playsinline
           plays-inline
           loop 
           class="video">
      <source src="/video/screen-a-low-b.mp4"
              type="video/mp4">
    </video>
  </div>
</template>
<script>
export default {
  props: ['hex'],
  mounted() {
    let testPlay = (e) => {
      let inView = this.isScrolledIntoView(this.$refs.video, -300)
      if (inView) this.$refs.video.play()
    };

    document.addEventListener('touchmove', testPlay);
    document.addEventListener('scroll', testPlay);

    this.$refs.video.controls = false;
  },
  computed: {
    background() {
      return 'linear-gradient(180deg, ' + 
                this.convertToRGBA(this.hex, 0.4) + ' 0%, ' +
                this.convertToRGBA(this.hex, 0.8) + ' 50%,' +
                this.convertToRGBA(this.hex, 1) + ' 100%)'
    }
  },

  methods: {
    isScrolledIntoView: (el, offset) => {
      let rect = el.getBoundingClientRect();
      let elemTop = rect.top - offset;
      let elemBottom = rect.bottom;

      // Only completely visible elements return true:
      // let isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
      // Partially visible elements return true:
      return elemTop < window.innerHeight && elemBottom >= 0;
    },
    // eslint-disable-next-line
    convertToRGBA: (hex, alpha) => {
      // eslint-disable-next-line
      return 'rgba('+parseInt(hex.substring(1,3),16)+', '+parseInt(hex.substring(3,5),16)+', '+parseInt(hex.substring(5,7),16)+', '+alpha+')';
    }
  },
};
</script>

<style lang="scss">
video {
  pointer-events: none;
}
video::-webkit-media-controls {
  display: none;
}

.video {
  pointer-events: none;
}

.video::-webkit-media-controls-panel {
    display: none!important;
    -webkit-appearance: none;
}

.video::--webkit-media-controls-play-button {
    display: none!important;
    -webkit-appearance: none;
}

.video::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
}
</style>
