<template>
  <div class="hue-picker" ref="huePicker" v-bind:class="{ dragging: dragging }">
    <drag-area @new-pos="newPos"
      padding-x="10" padding-y="4"
      @drag-start="dragging = true" @drag-end="dragging = false" />
    <div class="marker"
      v-bind:style="{
        left: pos, background: background }"/>
    <div class="hue-picker-back"></div>
  </div>
</template>

<script>
import Color from 'color'
import DragArea from './DragArea.vue'

export default {
  props: ['selected'],
  components: { DragArea },
  data() {
    return { pos: 0, dragging: false }
  },
  mounted() {
    this.calcPosition()
    window.addEventListener('resize', this.calcPosition)
  },
  methods: {
    calcPosition() {
      if (this.$refs.huePicker) {
        this.pos = this.$refs.huePicker.clientWidth * this.hue / 360 + 'px'
      }
    },
    newPos(e) {
      this.$emit('new-pos', e.x)
    }
  },
  computed: {
    hue() {
      return this.selected.hue()
    },
    background() {
      return Color.hsl([this.hue, 100, 50]).hex()
    }
  },
  watch: {
    hue() {
      this.calcPosition()
    },
  },
};
</script>

<style lang="scss">
.hue-picker {
  margin: 0 auto;
  width: 96%;
  height: 20px;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;

  .hue-picker-back {
    background: linear-gradient(to right, rgb(255, 0, 0) 0%, rgb(255, 255, 0) 17%, rgb(0, 255, 0) 33%, rgb(0, 255, 255) 50%, rgb(0, 0, 255) 67%, rgb(255, 0, 255) 83%, rgb(255, 0, 0) 100%);
    position: absolute;
    top: 6px;
    left: 0px;
    bottom: 6px;
    right: 0px;
    border-radius: 4px;
    pointer-events: none;
    user-select: none;
  }

  .marker {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    height: 19px;
    width: 19px;
    background: red;
    box-shadow: 0 1px 3px rgba(0,0,0,.44);
    border-radius: 12px;
    border: 3px solid #fff;
    box-sizing: border-box;
    transform: translateX(-9px);
    z-index: 2;
    user-select: none;
    pointer-events: none;
  }
}

.hue-picker.dragging {
  cursor: none !important;
    .marker {
      // border-color: white !important;
      box-shadow: 0 0 5px rgba(0,0,0,.3);
      cursor: none !important;
      border: 2px solid white;
      // width: 16px;
      // height: 16px
    }
}
</style>
