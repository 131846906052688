<template>
  <div class="color-field" ref="main"
    v-bind:class="{ dragging: dragging }">
    <drag-area @new-pos="newPos"
        padding-x="10" padding-y="8"
        @drag-start="dragging = true" @drag-end="dragging = false" />
    <drop :selected="selected" />
    <background :hue="selected.hue()" :compare="compare"
   />
  </div>
</template>
<script>
import Color from 'color';
import Background from './ColorFieldBackground.vue';
import Drop from './Drop.vue';
import DragArea from './DragArea.vue';

export default {
  components: { Background, Drop, DragArea },
  mounted() {
  },
  props: ['compare', 'selected'],
  data() {
    return {
      dragging: false
    }
  },
  computed: {
  },
  methods: {
    newPos(e) {
      this.$emit('new-pos', e)
    }
  }
};
</script>

<style lang="scss">
@import './color-field';
</style>
