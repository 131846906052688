<template>
  <div class="dropdown-menu" v-bind:class="{ 'dropdown-menu-active': open }">
    <div @click="open = !open" class="dropdown-title">
      {{active}}
      <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M4 3L0.535898 0L7.4641 0L4 3Z" fill="black"/>
      </svg>
    </div>
    <div class="dropdown-options" v-show="open">
      <div v-for="(option, index) in options" v-bind:key="index"
          @click="select(option.value)">
           {{option.label}}
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['active', 'options'],
  mounted() {},
  data() {
    return {
      open: false
    };
  },
  computed: {
  
  },
  methods: {
    select(value) {
      this.$emit('change', value)
      this.open = false
    }
  },
  watch: {},
};
</script>

<style lang="scss">
.dropdown-menu {
  position: relative;
  user-select: none;
  .dropdown-title {
    cursor: pointer;
    font-weight: 500;
    padding: 4px 9px;

    svg {
      position: absolute;
      right: 9px;
      top: 9px;
    }
  }
  .dropdown-options {
    background: white;
    padding: 4px 0px;
    position: absolute;
    top: 26px;
    left: 0;
    right: 0;
    z-index: 11;
    box-shadow: 0 3px 10px rgba(0,0,0,0.3);
    border-radius: 4px;
    div {
      cursor: pointer;
      padding: 7px 12px;
    }
    div:last-child {
      margin-bottom: 0;
    }
  }
 
}
.dropdown-menu-active {
  background: white;
  z-index: 12;
  .dropdown-title {
    svg {
      transform: rotate(180deg);
    }
  }
}
</style>
