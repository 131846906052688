<template>
  <div id="app">
    <browser-warning />
    <router-view></router-view>
  </div>
</template>

<script>
import BrowserWarning from './components/BrowserWarning.vue'

export default {
  components: { BrowserWarning }
}
</script>
  
<style lang="scss">
@import './style';
</style>
