<template>
  <div class="drag-area" ref="main"

    v-pan-start="onPanStart"
    v-pan-end="onPanEnd"
    v-pan-move="onPanMove"
    v-tap="onTap"
    v-press="onPress"
    v-press-up="onPressUp"

    @mousedown="startDrag"
    @mousemove="doDrag"
    @mouseup="endDrag"
    @mouseleave="endDrag"

    v-bind:style="{
      left: -rPX + 'px',
      right: -rPX + 'px',
      top: -rPY + 'px',
      bottom: -rPY + 'px',
      zIndex: zIndex
    }"
  >

  </div>
</template>

<script>
export default {
  mounted() {
  },
  props: ['paddingX', 'paddingY'],
  data() {
    return {
      dragging: false
    }
  },
  computed: {
    rPX() {
      return Number(this.paddingX) + 1000 * Number(this.dragging)
    },
    rPY() {
      return Number(this.paddingY) + 1000 * Number(this.dragging)
    },
    zIndex() {
      return this.dragging ? 10 : 0
    }
  },
  watch: {
    dragging(value) {
      if (value) this.$emit('drag-start')
      else this.$emit('drag-end')
    }
  },
  methods: {
    startDrag(e) {
      if (window.USER_IS_TOUCHING) return
      this.newPos(e)
      this.dragging = true
    },
    endDrag(e) {
      if (window.USER_IS_TOUCHING) return
      this.dragging = false
    },
    doDrag(e) {
      if (window.USER_IS_TOUCHING) return
      if (!this.dragging) return
      this.newPos(e)
    },
    onTap(e) {
      this.newPos(e.center)
    },
    onPress(e) {
      this.newPos(e.center)
    },
    onPressUp(e) {
    },
    onPanStart(e) {
      // this.dragging = true
    },
    onPanEnd(e) {
      // this.dragging = false
    },
    onPanMove(e) {
      if (!window.USER_IS_TOUCHING) return
      this.newPos(e.center)
    },
    newPos(e) {
      // this.lasse = .touches[0].pageX
      let container = this.$refs.main.getBoundingClientRect()
      // Older iOS fix (don't have container.x)
      let cx = container.x ? container.x : container.left
      let cy = container.y ? container.y : container.top

      let x = (e.x - cx - this.rPX) / (container.width - this.rPX * 2)
      let y = (e.y - cy - this.rPY) / (container.height - this.rPY * 2)

      x = x < 0.001 ? 0.001 : x
      x = x > 0.999 ? 0.999 : x

      y = y < 0 ? 0 : y
      y = y > 0.999 ? 0.999 : y
      this.$emit('new-pos', { x, y })
    }
  }
}
</script>

<style lang="scss">
.drag-area {
  // color: black;
  position: absolute;
  user-select: none;
}
</style>
