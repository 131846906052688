<template>
  <div class="color-field-back">
    <contrast-line :hue="hue" :target="3" :compare="compare" type="positive" class="positive" />
    <contrast-line :hue="hue" :target="3" :compare="compare" type="negative" />
    <contrast-line :hue="hue" :target="4.5" :compare="compare" type="positive"  class="positive main-line"  />
    <contrast-line :hue="hue" :target="4.5" :compare="compare" type="negative" class="main-line" />
    <contrast-line :hue="hue" :target="7" :compare="compare" type="positive"  class="positive good-line" />
    <contrast-line :hue="hue" :target="7" :compare="compare" type="negative" class="good-line" />
    <div class="back"
      v-bind:style="{
        background:
          'linear-gradient(to top, rgb(0, 0, 0), transparent),' +
          'linear-gradient(to left, ' + hsvColor + ', rgb(255, 255, 255))'
      }" />
  </div>
</template>

<script>
import Color from 'color';
import ContrastLine from './ContrastLine.vue'

export default {
  components: { ContrastLine },
  mounted() {
  },
  props: ['hue', 'compare'],
  data() {
    return {};
  },
  computed: {
    hsvColor() {
      return Color.hsv(this.hue, 100, 100).toString();
    },
  },
};
</script>

<style lang="scss">
.color-field-back {
  width: 100%;
  height: 100%;

  .back {
    width: 100%;
    height: 100%;
    border-radius: 0px;

  }
}
</style>
