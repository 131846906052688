import Color from 'color';

const luminosity = (rgb) => {
  // http://www.w3.org/TR/WCAG20/#relativeluminancedef
  const lum = [];
  for (let i = 0; i < rgb.length; i++) {
    const chan = rgb[i] / 255;
    // eslint-disable-next-line
    lum[i] = (chan <= 0.03928) ? chan / 12.92 : Math.pow(((chan + 0.055) /
      1.055), 2.4);
  }

  return 0.2126 * lum[0] + 0.7152 * lum[1] + 0.0722 * lum[2];
};

const contrast = (color1, color2, type) => {
  // http://www.w3.org/TR/WCAG20/#contrast-ratiodef
  const lum1 = luminosity(color1);
  const lum2 = luminosity(color2);

  if (type === 'negative') {
    return (lum2 + 0.05) / (lum1 + 0.05);
  }
  return (lum1 + 0.05) / (lum2 + 0.05);
};

const getContrast = (source, hue, saturation, brightness, type) => contrast(
  source, Color.hsv(hue, saturation, brightness).rgb().color, type
)


const findHsvBrightness = (data, min, max, rounds, round) => {
  let result = []
  for (let i = 0; i <= 10; i++) {
    let b = ((max - min) / 10) * i + min
    result.push([b, getContrast(data.source, data.hue, data.saturation, b,
      data.type)])
  }

  let distance = result.map(v => [v[0], v[1] - data.target])
  let positive = distance.filter(v => v[1] >= 0)
  let negative = distance.filter(v => v[1] <= 0)

  if (!negative.length) return 101
  if (!positive.length) return 101

  let smallestPositive = positive.reduce((v, b) => (v[1] >= b[1] ? b : v))
  let smallestNegative = negative.reduce((v, b) => (v[1] <= b[1] ? b : v))

  round++
  if (round >= rounds) {
    return smallestPositive[0]
  }

  return findHsvBrightness(data, smallestPositive[0], smallestNegative[0],
    rounds, round)
}


const calcHsv = (color, hue, resolution, target, type) => {
  const result = [];
  const step = 100 / resolution;
  for (let i = 0; i <= resolution; i++) {
    result.push([
      i * step / 100,
      findHsvBrightness({
        source: color,
        hue,
        saturation: i * step,
        target,
        type
      }, 0, 100, 4, 0) / 100,
    ]);
  }

  return result;
};

export default {
  calcHsv,
  contrast,
  findHsvBrightness,
};
