import Vue from 'vue'
import Router from 'vue-router'
import Start from './Start.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Start',
      component: Start
    },
    {
      path: '/check',
      redirect: '/',
    },
    {
      path: '/check/',
      redirect: '/',
    },
    {
      path: '/check/:colors',
      name: 'StartCheck',
      component: Start
    }
  ]
})
