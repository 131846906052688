<template>
  <span class="check-holder">
     <svg :fill="background" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 7 7" style="enable-background:new 0 0 7 7;" xml:space="preserve"><path d="M5.8,0.6c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-3,3.9C1.8,3.7,1.4,3.4,1.3,3.3C1.3,3.3,1.2,3.3,1.2,3.3c0,0-0.1,0-0.1,0.1L0.4,4
c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l0.1,0c1.4,1.4,2.1,2.1,2.2,2.1c0.1,0.1,0.1,0.1,0.1,0.1s0.1,0,0.1-0.1l3.8-4.9c0,0,0,0,0-0.1
c0-0.1,0-0.1,0-0.1L5.8,0.6z"/></svg>
    <input type="checkbox"
           :value="value"
           :name="name"
           :id="id"
           v-bind:style="{
                background: boxBack,
                borderColor: background
              }"
           class="styled-checkbox"
           @change="change">
   
    <label :for="id">
      {{ label }}
    </label>
  </span>
</template>

<script>
export default {
  props: ['background', 'foreground', 'label', 'value', 'name', 'id'],
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    boxBack() {
      return this.checked ? this.foreground : this.background;
    },
  },
  methods: {
    change(e) {
      this.checked = e.srcElement.checked;
    },
  },
};
</script>

<style lang="scss" scoped>
.check-holder {
  display: flex;
  // justify-content: center;
  position: relative;
  svg {
    width: 9px;
    position: absolute;
    top: 7px;
    left: 6px;
    z-index: 2;
    pointer-events: none;
    user-select: none;
  }
}
.styled-checkbox {
  appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  width: 15px;
  height: 15px;
  border-radius: 6px;
  display: inline-block;
  position: relative;
  border: 2px solid white;
}

</style>
