<template>
  <div class="aa-inline">
    <svg v-if="contrast < aa"
         width="34"
         height="18"
         viewBox="0 0 34 18"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M1.11 14V3.35H8.265V5.165H3.18V7.91H7.41V9.59H3.18V14H1.11ZM12.9033 3.35H14.7333L18.7833 14H16.6533L15.6633 11.345H11.9433L10.9683 14H8.83828L12.9033 3.35ZM15.2883 9.89L13.8183 5.705L12.2883 9.89H15.2883ZM20.5514 14V3.35H22.6214V14H20.5514ZM25.4434 14V3.35H27.5134V12.185H32.9434V14H25.4434Z"
            fill="#BB2E2E" />
    </svg>

    <svg v-if="contrast >= aa && contrast < aaa"
         width="34"
         height="18"
         viewBox="0 0 34 18"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M4.14 3.35H5.97L10.02 14H7.89L6.9 11.345H3.18L2.205 14H0.075L4.14 3.35ZM6.525 9.89L5.055 5.705L3.525 9.89H6.525ZM13.6181 3.35H15.4481L19.4981 14H17.3681L16.3781 11.345H12.6581L11.6831 14H9.55312L13.6181 3.35ZM16.0031 9.89L14.5331 5.705L13.0031 9.89H16.0031Z"
            fill="#008947" />
      <path d="M27.3996 3.23755C24.4373 3.23755 22.0371 5.63778 22.0371 8.60005C22.0371 11.5623 24.4373 13.9625 27.3996 13.9625C30.3619 13.9625 32.7621 11.5623 32.7621 8.60005C32.7621 5.63778 30.3619 3.23755 27.3996 3.23755ZM30.1453 7.11763L26.6984 10.58H26.6958C26.652 10.6239 26.5334 10.7218 26.3967 10.7218C26.2987 10.7218 26.1879 10.6677 26.0951 10.5749L24.6513 9.13114C24.6101 9.08989 24.6101 9.02544 24.6513 8.98419L25.1102 8.52528C25.1309 8.50466 25.1566 8.49435 25.1824 8.49435C25.2082 8.49435 25.234 8.50466 25.2546 8.52528L26.3993 9.66997L29.5446 6.50145C29.5652 6.48083 29.591 6.47052 29.6168 6.47052C29.6452 6.47052 29.6709 6.48083 29.689 6.50145L30.1402 6.9681C30.1866 7.01192 30.1866 7.07638 30.1453 7.11763V7.11763Z"
            fill="#008947" />
    </svg>

    <svg v-if="contrast >= aaa"
         width="43"
         height="18"
         viewBox="0 0 43 18"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M4.14 3.35H5.97L10.02 14H7.89L6.9 11.345H3.18L2.205 14H0.075L4.14 3.35ZM6.525 9.89L5.055 5.705L3.525 9.89H6.525ZM13.6181 3.35H15.4481L19.4981 14H17.3681L16.3781 11.345H12.6581L11.6831 14H9.55312L13.6181 3.35ZM16.0031 9.89L14.5331 5.705L13.0031 9.89H16.0031ZM23.0962 3.35H24.9262L28.9762 14H26.8462L25.8562 11.345H22.1362L21.1612 14H19.0312L23.0962 3.35ZM25.4812 9.89L24.0112 5.705L22.4812 9.89H25.4812Z"
            fill="#008947" />
      <path d="M36.3996 3.23755C33.4373 3.23755 31.0371 5.63778 31.0371 8.60005C31.0371 11.5623 33.4373 13.9625 36.3996 13.9625C39.3619 13.9625 41.7621 11.5623 41.7621 8.60005C41.7621 5.63778 39.3619 3.23755 36.3996 3.23755ZM39.1453 7.11763L35.6984 10.58H35.6958C35.652 10.6239 35.5334 10.7218 35.3967 10.7218C35.2987 10.7218 35.1879 10.6677 35.0951 10.5749L33.6513 9.13114C33.6101 9.08989 33.6101 9.02544 33.6513 8.98419L34.1102 8.52528C34.1309 8.50466 34.1566 8.49435 34.1824 8.49435C34.2082 8.49435 34.234 8.50466 34.2546 8.52528L35.3993 9.66997L38.5446 6.50145C38.5652 6.48083 38.591 6.47052 38.6168 6.47052C38.6452 6.47052 38.6709 6.48083 38.689 6.50145L39.1402 6.9681C39.1866 7.01192 39.1866 7.07638 39.1453 7.11763V7.11763Z"
            fill="#008947" />
    </svg>

  </div>

</template>

<script>
export default {
  props: ['contrast', 'aa', 'aaa'],
};
</script>

<style lang="scss">
</style>
