/* eslint-disable  */
import Hammer from 'hammerjs';


let init = (Vue) => {

  Vue.directive("tap", {
    bind: function(el, binding) {
      if (typeof binding.value === "function") {
        const mc = new Hammer(el);
        mc.on('tap', binding.value);
      }
    }
  });  
  Vue.directive("press", {
    bind: function(el, binding) {
      if (typeof binding.value === "function") {
        const mc = new Hammer(el);
        mc.on('press', binding.value);
      }
    }
  });  
  Vue.directive("press-up", {
    bind: function(el, binding) {
      if (typeof binding.value === "function") {
        const mc = new Hammer(el);
        mc.on('pressup', binding.value);
      }
    }
  });  

  Vue.directive("pan-start", {
    bind: function(el, binding) {
      if (typeof binding.value === "function") {
        const mc = new Hammer(el);
        mc.on('panstart', binding.value);
      }
    }
  });  

  Vue.directive("pan-end", {
    bind: function(el, binding) {
      if (typeof binding.value === "function") {
        const mc = new Hammer(el);
        mc.on('panend', binding.value);
      }
    }
  });  

  Vue.directive("pan-move", {
    bind: function(el, binding) {
      if (typeof binding.value === "function") {
        const mc = new Hammer(el);
        mc.on('panmove', binding.value);
        mc.get('pan').set({ direction: Hammer.DIRECTION_ALL, threshold: 0  });
      }
    }
  });  

}



export default {
  init
}

