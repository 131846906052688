<template>
  <div class="color-drop" ref="main"
    v-bind:class="{ 'dragging': dragging }"
    v-bind:style="{
      left: hsv[1] + '%',
      top: (100 - hsv[2]) + '%',
      background: selected.hex()
      }"
 >
  </div>
</template>
<script>
export default {
  components: { },
  props: ['hue', 'selected'],
  data() {
    return {
      dragging: false,
    };
  },
  computed: {
    hsv() {
      return this.selected.array()
    }
  },
};
</script>

<style lang="scss">
.color-drop {
  font-size: 8px;
}
</style>
