<template>
  <div class="footer" v-bind:style="{ color: hex, background: background}">
    <div class="footer-tri">
      <svg version="1.1"
             id="Layer_1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px"
             y="0px"
             viewBox="0 0 293 94"
             style="enable-background:new 0 0 293 94;"
             xml:space="preserve"
             preserveAspectRatio="none"
             :fill="hex">
          <polygon points="0,0 293,0 293,94 " />
        </svg>
    </div>
    <div class="content">


      Made with love by
      <a href="https://robsarve.co" target="_blank">Anton Robsarve</a>,
      Illustration by <a href="https://twitter.com/pablostanley" target="_blank">Pablo Stanley</a>
    </div>
  </div>
</template>
<script>
export default {
  props: ['hex'],
  mounted() {
  },
  computed: {
    contrast() {
      return Number(this.$store.getters.contrast);
    },
    background() {
      return this.$store.getters.background.color.hex()
    }
  },
  methods: {

  }
};
</script>

<style lang="scss">
.footer {
   font-family: 'Raleway', sans-serif;
  .content {
    max-width: 700px;
    padding: 20px;
    padding-top: 180px;
    padding-bottom: 60px;
    font-size: 12px;

  }
}
.footer-tri {
  transform: translateY(-1px);
  overflow: hidden;
  svg {
    height: 45px;
    width: 120%;
  }
}
</style>
