const defaultWidth = 2
const defaultLength = 5

export default {
  hsl: {
    rounding: 0,
    width: defaultWidth,
    type: 'number',
    fields: [
      { length: defaultLength, label: 'h', max: '360' },
      { length: defaultLength, label: 's', max: '100' },
      { length: defaultLength, label: 'l', max: '100' },
    ]
  },
  hsv: {
    rounding: 0,
    width: defaultWidth,
    type: 'number',
    fields: [
      { length: defaultLength, label: 'h', max: '360' },
      { length: defaultLength, label: 's', max: '100' },
      { length: defaultLength, label: 'v', max: '100' },
    ]
  },
  cmyk: {
    rounding: 0,
    width: defaultWidth,
    type: 'number',
    fields: [
      { length: defaultLength, label: 'c', max: '100' },
      { length: defaultLength, label: 'm', max: '100' },
      { length: defaultLength, label: 'y', max: '100' },
      { length: defaultLength, label: 'k', max: '100' },
    ]
  },
  rgb: {
    rounding: 0,
    width: defaultWidth,
    type: 'number',
    min: '3',
    fields: [
      { length: defaultLength, label: 'r', max: '255' },
      { length: defaultLength, label: 'g', max: '255' },
      { length: defaultLength, label: 'b', max: '255' },
    ]
  },
  hex: {
    rounding: false,
    width: 4,
    type: 'text',
    fields: [
      { length: 7, label: '#' },
    ]
  },
  hslCss: {
    rounding: false,
    width: 11,
    type: 'text',
    fields: [
      { length: 30, label: '' },
    ]
  },
  rgbCss: {
    rounding: false,
    width: 8.5,
    type: 'text',
    fields: [
      { length: 20, label: '' },
    ]
  },
}
