<template>
  <div class="picker" v-bind:style="{ boxShadow }">

    <div class="left">
      <color-field :compare="notSelected"
                   :selected="selected"
                   @new-pos="onNewPos" />
      <hue-picker :selected="selected"
                  @new-pos="onNewHuePos" />

    </div>

    <div class="right">

      <div class="menu hide-on-mobile">
        <div class="menu-left">
          <div class="text-button flip-button" @click="flip">
            Flip
            <flip-icon/>  
          </div>
        </div>
        <div style="flex-grow: 1" />
        <div class="menu-right">
          <div class="text-button" @click="toggleColorMode('rgb')" v-bind:class="{ active: colorModeActive('rgb') }">RGB</div>
          <div class="text-button" @click="toggleColorMode('hsl')" v-bind:class="{ active: colorModeActive('hsl') }">HSL</div>
          <div class="text-button" @click="toggleColorMode('cmyk')" v-bind:class="{ active: colorModeActive('cmyk') }">CMYK</div>
          <div class="text-button" @click="toggleColorMode('css')" v-bind:class="{ active: colorModeActive('css') }">CSS</div>
        </div>
      </div>

      <color-selector target="foreground" label="Foreground" />
      <color-selector target="background" label="Background" />

      <div class="flip-button-mobile">
        <div class="text-button flip-button" @click="flip">
          Flip
          <flip-icon/>  
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import Color from 'color';
import ColorField from './ColorField.vue';
import HuePicker from './HuePicker.vue';
import ColorSelector from './ColorSelector.vue';
import FlipIcon from './FlipIcon.vue';

export default {
  components: {
    ColorField, HuePicker, ColorSelector, FlipIcon
  },
  mounted() {
    this.$emit('updated', this.selected);
  },
  data() {
    return {
      compareColor: '#FFFFFF',
    };
  },
  computed: {
    selected() {
      return this.$store.getters.selected.color.hsv();
    },
    notSelected() {
      return this.$store.getters.notSelected.color.hsv();
    },
    background() {
      return this.$store.getters.background.color.hsv();
    },
    foreground() {
      return this.$store.getters.foreground.color.hsv();
    },
    contrast() {
      return Number(this.$store.getters.contrast).toFixed(1);
    },
    boxShadow() {
      return '0px 1px 1px hsla(' + this.foreground.hue() + ', 43%, 29%, 0.06),' +
             '0px 4px 9px hsla(' + this.foreground.hue() + ', 43%, 29%, 0.03),' +
             '0px 16px 80px hsla(' + this.foreground.hue() + ', 43%, 29%, 0.08)'
    }
  },
  methods: {
    onNewPos(e) {
      this.$store.commit('setHSVColor', {
        saturation: e.x * 100,
        brightness: 100 - e.y * 100,
      });
    },
    onNewHuePos(e) {
      this.$store.commit('setHSVColor', { hue: e * 360 });
    },
    setSelected(value) {
      this.$store.commit('setSelected', value);
    },
    hasGlobalFocus(name) {
      return this.$store.state.colors.selected === name
    },
    flip() {
      this.$store.dispatch('flipColors');
    },
    toggleColorMode(mode) {
      return this.$store.commit('toggleColorMode', mode)
    },
    colorModeActive(mode) {
      return this.$store.getters.showColorMode(mode)
    }
  },
  watch: {
    selected(value) {
      this.$emit('updated', this.selected);
    },
  },
};
</script>

<style lang="scss">
@import './picker';
</style>
