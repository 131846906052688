<template>
  <div class="color-selector">

      <div class="pallate pallate-foreground"
          @click="setSelected()"
          v-bind:style="{ background: activeColor.color.hex(), color: reverseColor.color.hex() }"
          v-bind:class="{ active: hasGlobalFocus() }">
        <div class="pallate-name">
          {{ label }}

        </div>
        <div class="active"
            v-show="hasGlobalFocus()"
            v-bind:style="{ background: reverseColor.color.hex() }" />
      </div>
   

      <div class="color-inputs hide-on-mobile">

        <div class="r1">
          <EyeDropper :target="target"/>
        </div>
        <div class="r2">
          <div class="color-input-row" v-show="showColorMode('rgb')">
            <color-input mode="hex" :target="target"/>
            <div style="flex: 1"></div>
            <color-input mode="rgb" :target="target"/>
          </div>

          <div class="color-input-row" v-show="showColorMode('hsl')">
            <color-input mode="hsl" :target="target"/>
          </div>

          <div class="color-input-row" v-show="showColorMode('hsv')">
            <color-input mode="hsv" :target="target"/>
          </div>

          <div class="color-input-row" v-show="showColorMode('cmyk')">
            <color-input mode="cmyk" :target="target"/>
          </div>

          <div class="color-input-row" v-show="showColorMode('css')">
            <color-input mode="rgbCss" :target="target" class="small"/>
            <div style="flex: 1"></div>
            <color-input mode="hslCss" :target="target" class="small"/>
          </div>
        </div>


      </div>
 


  </div>
</template>
<script>
import Color from 'color'
import ColorInput from './ColorInput.vue'
import EyeDropper from './EyeDropper.vue'

export default {
  components: { ColorInput, EyeDropper },
  props: ['target', 'label'],
  mounted() {},
  data() {
    return {};
  },
  computed: {
    activeColor() {
      return this.target === 'foreground'
        ? this.$store.getters.foreground
        : this.$store.getters.background;
    },
    reverseColor() {
      return this.target === 'foreground'
        ? this.$store.getters.background
        : this.$store.getters.foreground;
    },
  },
  methods: {
    hasGlobalFocus() {
      return this.$store.state.picker.selected === this.target;
    },
    setSelected() {
      this.$store.commit('setSelected', this.target);
    },
    showColorMode(mode) {
      return this.$store.getters.showColorMode(mode)
    }
  },
  watch: {},
};
</script>

<style lang="scss">
@import './color-selector';
</style>
