<template>
  <div ref="outer" class="contrast1">
    <div class="indicator" v-if="leftVal > 0.4 && leftVal < 99.6"
        v-bind:style="{top: leftVal + '%'}">{{target}}</div>
    <svg ref="svg" viewBox="0 0 100 100" shape-rendering="geometricPrecision" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" class="svg">
    </svg>
  </div>
</template>

<script>
import Color from 'color'
import ContrastHelper from '../../tools/ContrastHelper'
import SVGHelper from '../../tools/SVGHelper'

export default {
  components: {
  },
  props: ['hue', 'target', 'compare', 'type'],
  data() {
    return {
      leftVal: null,
    }
  },
  mounted() {
    this.position()
    this.$nextTick(() => {
      this.draw()
    })
  },
  watch: {
    hue(newVal) {
      this.draw()
    },
  },
  computed: {
  },
  methods: {
    position() {
      this.canvasWidth = this.$refs.outer.clientWidth
      this.canvasHeight = this.$refs.outer.clientHeight
    },
    draw() {
      const v = ContrastHelper.calcHsv(this.compare.rgb().color, this.hue, 10, this.target, this.type)
      let ts = []
      v.forEach((element) => {
        element[0] *= 100
        element[1] = 100 - element[1] * 100
        ts.push(element[1])
      });
      // eslint-disable-next-line
      this.leftVal = ts[0]
      SVGHelper.create(this.$refs.svg, v)
    },
  },
}


</script>


<style lang="scss">
.indicator {
  position: absolute;
  font-size: 11px;
  text-align: right;
  width: 24px;
  left: -34px;
  top: 50%;
  // transform: translateY(-6px);
  opacity: 0.8;
  font-weight: 500;
}
.contrast1 {
  // background: yellow;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  position: absolute;
  pointer-events: none;
  svg {
    // opacity: 0.5;
    overflow: hidden;
    width: 100%;
    height: 100%;
    // height: 200px;
    // width: 300px;
    path {
      stroke: white;
      stroke-width: 0.35px;
      opacity: 0.5;
    }
  }
}
.positive {
  svg {
    path {
      // stroke: white !important;
    }
  }
}
.main-line {
  svg {
    path {
      stroke: white !important;
      opacity: 1;
    }
  }
}
.good-line {
  svg {
    path {
      // opacity: 1;
    }
  }
}

</style>
