var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"pan-start",rawName:"v-pan-start",value:(_vm.onPanStart),expression:"onPanStart"},{name:"pan-end",rawName:"v-pan-end",value:(_vm.onPanEnd),expression:"onPanEnd"},{name:"pan-move",rawName:"v-pan-move",value:(_vm.onPanMove),expression:"onPanMove"},{name:"tap",rawName:"v-tap",value:(_vm.onTap),expression:"onTap"},{name:"press",rawName:"v-press",value:(_vm.onPress),expression:"onPress"},{name:"press-up",rawName:"v-press-up",value:(_vm.onPressUp),expression:"onPressUp"}],ref:"main",staticClass:"drag-area",style:({
    left: -_vm.rPX + 'px',
    right: -_vm.rPX + 'px',
    top: -_vm.rPY + 'px',
    bottom: -_vm.rPY + 'px',
    zIndex: _vm.zIndex
  }),on:{"mousedown":_vm.startDrag,"mousemove":_vm.doDrag,"mouseup":_vm.endDrag,"mouseleave":_vm.endDrag}})
}
var staticRenderFns = []

export { render, staticRenderFns }